import React from "react"
import GlitchText from "../subComponents/GlitchText"
import WelcomeText from "../assets/Images/wtmp.png"
import { motion } from "framer-motion"
import styled from "styled-components"
import ScrollToBottomButton from "../subComponents/ScrollDownButton"

const ScreenVideoComponent = ({ isVisible, containerVariants, videoRef }) => {
  const FullscreenVideoContainer = styled(motion.div)`
    width: 100%;
    height: 100vh;
    background: #061729;
  `

  const FullscreenVideo = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `

  const ContentContainer = styled.div`
    position: absolute;
    width: 100%;
    heigth: 100%;
    top: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff93c;
    font-family: "Cyberpunk", "Righteous", sans-serif;
    font-size: 2.5rem;
    left: 20%;
    text-align: center;

    @media only screen and (max-width: 800px) {
      position: absolute;
      top: 90%;
      left: -2%;
    }
  `
  //   const TextContainer = styled.h1`
  //     display: none;
  //     @media only screen and (max-width: 800px) {
  //       font-size: 8px;
  //       display: block;
  //       line-height: 250%;
  //       letter-spacing: 10px;
  //     }
  //   `

  const TextImage = styled.img`
     {
      display: none;
      @media only screen and (max-width: 800px) {
        display: block;
        width: 210px;
      }
    }
  `

  return (
    <>
      <FullscreenVideoContainer
        initial={{ y: 0 }}
        animate={!isVisible ? { y: "-100%" } : { y: 0 }}
        variants={containerVariants}
        transition={{ duration: 1 }}
      >
        {window.innerWidth <= 768 ? (
          <FullscreenVideo
            ref={videoRef}
            muted
            poster="https://res.cloudinary.com/dcdchgx6z/image/upload/v1715414771/bg-vr_s0ego9.png"
            autoPlay
          >
            <source
              src={require("../assets/video/bg-vr.mp4")}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </FullscreenVideo>
        ) : (
          <FullscreenVideo
            ref={videoRef}
            muted
            poster="https://res.cloudinary.com/dcdchgx6z/image/upload/v1715414770/bg-hr_xjr0uq.png"
            autoPlay
          >
            <source
              src={require("../assets/video/bg-hr.mp4")}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </FullscreenVideo>
        )}

        <ContentContainer>
          {/* <TextContainer> */}
          <motion.div
            initial={{ x: 500 }}
            animate={{ x: 0 }}
            transition={{ type: "spring", stiffness: 120, damping: 10 }}
          >
            <GlitchText
              dataText={"WELCOME TO M"}
              title={"WELCOME TO MY"}
              mobileVisible={true}
              desktopVisible={false}
              primaryColor={"#00F0FF"}
              secondaryColor={"#092436"}
              steps={30}
            />
          </motion.div>

          <motion.div
            initial={{ x: 500 }}
            animate={{ x: 0 }}
            transition={{ type: "spring", stiffness: 60 }}
          >
            <GlitchText
              dataText={"PORTFOLIO"}
              title={"PORTFOLIO"}
              mobileVisible={true}
              desktopVisible={false}
              primaryColor={"#00F0FF"}
              secondaryColor={"#092436"}
              steps={30}
            />
          </motion.div>

          <ScrollToBottomButton />

          {/* <TextContainer>
              <h1>WELCOME TO MY</h1>
              <h1>PORTFOLIO</h1>
            </TextContainer> */}
          <TextImage src={WelcomeText} alt="Welcome" />
        </ContentContainer>
      </FullscreenVideoContainer>
    </>
  )
}

export default ScreenVideoComponent
