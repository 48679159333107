import React from "react"
import styled, { keyframes } from "styled-components"

const GlitchText = ({
  dataText,
  title,
  mobileVisible,
  desktopVisible,
  primaryColor,
  secondaryColor,
  steps,
}) => {
  // const primaryColor = "#00F0FF"
  // const secondaryColor = "#092436"
  // const steps = 30

  console.log("dddd", desktopVisible, mobileVisible)

  const glitchEffect = keyframes`
    ${Array.from(
      { length: steps + 1 },
      (_, i) => `
        ${i * (100 / steps)}% {
            clip: rect(${Math.random() * 100}px, 9999px, ${
        Math.random() * 100
      }px, 0);
        }
    `
    )}
`

  const Glitch = styled.h1`
    position: relative;
    margin: 0 0;
    color: ${(props) => props.theme.body};
    font-size: 5rem;
    font-family: "Cyberpunk", sans-serif;
    font-weight: 600;
    text-align: justify;
    text-transform: uppercase;
    letter-spacing: 0.01em;
    transform: scale3d(1, 1, 1);
    display: ${(props) => (props.desktopCondition ? "none" : "block")};

    &::before,
    &::after {
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      background: ${secondaryColor};
      color: ${primaryColor};
      clip: rect(0, 900px, 0, 0);
    }

    &::before {
      left: 7px;
      text-shadow: 1px 0 green;
      animation: ${glitchEffect} 3s infinite linear alternate-reverse;
    }

    &::after {
      left: 3px;
      text-shadow: -1px 0 red;
      animation: ${glitchEffect} 2s infinite linear alternate-reverse;
    }

    // @media screen and (min-width: 1025px) {
    //   font-size: 5rem;
    // }

    @media (min-width: 1026px) and (max-width: 1680px) {
      font-size: 3.8rem;
    }

    @media (min-width: 1024px) and (max-width: 1025px) {
      font-size: 2rem;
    }

    @media only screen and (max-width: 800px) {
      font-size: calc(1.8em + 1.8vw);
      display: ${(props) => (props.mobileCondition ? "none" : "block")};
    }
  `

  return (
    <>
      <Glitch
        data-text={dataText}
        mobileCondition={mobileVisible}
        desktopCondition={desktopVisible}
      >
        {title}
      </Glitch>
    </>
  )
}

export default GlitchText
