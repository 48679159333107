import React, { useEffect, useRef, useState, Suspense } from "react";
import { lightTheme } from "./components/Themes";
import GlobalStyle from "./globalStyle";
import { ThemeProvider } from "styled-components";
import {
  Switch,
  Route,
  useLocation,
  BrowserRouter as Router,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import SoundBar from "./subComponents/SoundBar";
import Contact from "./components/Contact";
import Loader from "./components/Loader";
import ScreenVideoComponent from "./components/ScreenVideoComponent";
import ReactGA from "react-ga4";
import { HelmetProvider } from "react-helmet-async";
import NotFoundPage from "./components/404";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import MyJourney from "./components/MyJourney";
import LoadingProgress from "./components/LoadingProgress";
import bgHr from "./assets/Images/bg-hr.webp"
import bgVr from "./assets/Images/bg-vr.webp"

// Lazy-load components
const Main = React.lazy(() => import("./components/Main"));
const BlogPage = React.lazy(() => import("./components/Blog"));
const WorkPage = React.lazy(() => import("./components/Work"));
const AboutPage = React.lazy(() => import("./components/About"));
const MySkillsPage = React.lazy(() => import("./components/MySkills"));
const MyBlog = React.lazy(() => import("./components/MyBlog"));
const BlogDetail = React.lazy(() => import("./components/BlogDetail"));
const SnakeGame = React.lazy(() => import("./components/SnakeGame"));

ReactGA.initialize("G-3N1XV0V5J1");

const handleDownloadClick = () => {
  ReactGA.event({
    category: "User",
    action: "Download Resume",
    label: "Resume Download Button",
  });
};

function App() {
  const location = useLocation();
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 5000);

    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Autoplay failed:", error);
      });
    }
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);



  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => (prev < 100 ? prev + 1 : 100));
    }, 20);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {progress < 100 ? (
        <LoadingProgress progress={progress} />
      ) : (
        <Suspense fallback={<div className="container" style={{ backgroundImage: `url(${isDesktop ? bgHr : bgVr})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "100%", height: "100%", position: "fixed", top: "0", left: "0", zIndex: "9999"}}/>}>
          <HelmetProvider>
            {location.pathname === "/" && (
              <ScreenVideoComponent
                isVisible={isVisible}
                videoRef={videoRef}
              />
            )}
            <GlobalStyle />
            <ThemeProvider theme={lightTheme}>
              <SoundBar />
              <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.pathname}>
                  <Route exact path="/" component={Main} />
                  <Route
                    exact
                    path="/about"
                    render={(props) => (
                      <AboutPage handleClick={handleDownloadClick} {...props} />
                    )}
                  />
                  <Route exact path="/project" component={BlogPage} />
                  <Route exact path="/achievement" component={WorkPage} />
                  <Route exact path="/skills" component={MySkillsPage} />
                  <Route exact path="/contact" component={Contact} />
                  <Route exact path="/loader" component={Loader} />
                  <Route exact path="/my-journey" component={MyJourney} />
                  <Route exact path="/blog" component={MyBlog} />
                  {isDesktop ? (
                    <Route exact path="/game" component={SnakeGame} />
                  ) : (
                    <Route
                      exact
                      path="/game"
                      render={() => <Redirect to="/" />}
                    />
                  )}
                  <Route exact path="/blog/details/:id" component={BlogDetail} />
                  <Route path="*" component={NotFoundPage} />
                </Switch>
              </AnimatePresence>
            </ThemeProvider>
          </HelmetProvider>
        </Suspense>
      )}
    </>
  );
}

const Root = () => (
  <Router>
    <App />
  </Router>
);


export default Root;
