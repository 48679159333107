import styled from 'styled-components';
import { FaGithub, FaExternalLinkAlt } from 'react-icons/fa';
import { motion } from 'framer-motion';

const Section = styled.section`
  position: relative;
  padding: 2rem 0;
  overflow: hidden; /* Ensures the video doesn't overflow */
  z-index: 1;
   width: 100%;

  /* Video Background */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: none;
    opacity: 0.3; /* You can adjust the opacity of the video */
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
    // opacity: 0.5; /* Adjust video brightness */
  }
`;

const Container = styled.div`
  max-width: 1980px;
//   width: 95%;
  height: 756px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  @media (min-width: 768px) {
    flex-direction: row;
    margin: 3rem 0;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: sticky;
  top: 9rem;
  @media (min-width: 1024px) {
    width: 33.33%;
  }
  margin-top: 2rem;
  padding: 0 2rem;
  @media (min-width: 768px) {
    margin-top: 3rem;
  }
`;

const Heading = styled.p`
  color: #FFC100;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin-left: 0.5rem;
`;

const Title = styled.p`
  font-size: 2rem;
  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
  line-height: 1.5;
  margin-bottom: 0.5rem;
`;

const Button = styled.a`
  background-color: transparent;
  color: #FFC100;
  border: 1px solid #FFC100;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  transition: all 0.3s ease;
  &:hover {
    background-color: #FFC100;
    color: white;
    border: none;
    box-shadow: 0 5px 15px rgba(255, 193, 0, 0.3);
  }
`;

const RightColumn = styled.div`
  margin-left: 0;
  @media (min-width: 768px) {
    margin-left: 3rem;
  }
  width: 66.66%;
  position: relative;
`;

const TimelineContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.5rem;
  position: relative;
`;

const TimelineItem = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.reverse ? 'flex-end' : 'flex-start'};
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;
  .content {
    width: 41.66%;
    padding: 0.25rem 1rem;
    text-align: ${(props) => (props.reverse ? 'right' : 'left')};
    color: rgba(255, 255, 255, 0.9);
  }
`;

const Date = styled.p`
  color: #FFC100;
  margin-bottom: 0.75rem;
`;

const TitleItem = styled.h4`
  font-size: 1.125rem;
  @media (min-width: 768px) {
    font-size: 2rem;
  }
  font-weight: bold;
  margin-bottom: 0.75rem;
`;

const ProjectContainer = styled(motion.div)`
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  cursor: pointer;

  &:hover .overlay {
    opacity: 1;
    visibility: visible;
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  border-radius: 8px;
`;

const IconLink = styled.a`
  color: white;
  font-size: 2rem;
  margin: 0 1rem;
  transition: color 0.3s ease;
  
  &:hover {
    color: #FFC100;
  }
`;

const LineProgress = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  width: 4px;
  background-color: #FFC100;
  height: 100%;
  transform: translateX(-50%);
`;

const BottomImageContainer = styled.div`
  position: absolute;
  bottom: -3rem;
  left: 0;
  img {
    width: 120px;
    height: auto;
    border-radius: 8px;
  }
`;

const JourneyTimeline = () => {
  return (
    <Section>
      <video autoPlay loop muted playsInline>
        {/* <source src="../assets/video/grid-bg.webm" type="video/webm" /> */}
        <source
              src={require("../assets/video/grid-bg.webm")}
              type="video/mp4"
            />
      </video>
      <div className="container">
        <Container>
          <LeftColumn>
            <Heading>Working Process</Heading>
            <Title>Working Process of Fest</Title>
            <Button href="#">Explore Now</Button>
          </LeftColumn>

          <RightColumn>
            <TimelineContainer>
              {/* Line Progress */}
              <LineProgress />

              <TimelineItem reverse>
                <div className="content">
                  <Date>1-6 May, 2021</Date>
                  <TitleItem>Registration</TitleItem>
                  <ProjectContainer whileHover={{ scale: 1.05 }}>
                    <ProjectImage src="https://via.placeholder.com/500" alt="Project" />
                    <Overlay className="overlay">
                      <IconLink href="https://github.com" target="_blank" rel="noopener noreferrer">
                        <FaGithub />
                      </IconLink>
                      <IconLink href="https://your-live-site.com" target="_blank" rel="noopener noreferrer">
                        <FaExternalLinkAlt />
                      </IconLink>
                    </Overlay>
                  </ProjectContainer>
                </div>
              </TimelineItem>

              <TimelineItem>
                <div className="content">
                  <Date>6-9 May, 2021</Date>
                  <TitleItem>Participation</TitleItem>
                  <ProjectContainer whileHover={{ scale: 1.05 }}>
                    <ProjectImage src="https://via.placeholder.com/500" alt="Project" />
                    <Overlay className="overlay">
                      <IconLink href="https://github.com" target="_blank" rel="noopener noreferrer">
                        <FaGithub />
                      </IconLink>
                      <IconLink href="https://your-live-site.com" target="_blank" rel="noopener noreferrer">
                        <FaExternalLinkAlt />
                      </IconLink>
                    </Overlay>
                  </ProjectContainer>
                </div>
              </TimelineItem>

              <TimelineItem reverse>
                <div className="content">
                  <Date>10 May, 2021</Date>
                  <TitleItem>Result Declaration</TitleItem>
                  <ProjectContainer whileHover={{ scale: 1.05 }}>
                    <ProjectImage src="https://via.placeholder.com/500" alt="Project" />
                    <Overlay className="overlay">
                      <IconLink href="https://github.com" target="_blank" rel="noopener noreferrer">
                        <FaGithub />
                      </IconLink>
                      <IconLink href="https://your-live-site.com" target="_blank" rel="noopener noreferrer">
                        <FaExternalLinkAlt />
                      </IconLink>
                    </Overlay>
                  </ProjectContainer>
                </div>
              </TimelineItem>

              <TimelineItem>
                <div className="content">
                  <Date>12 May, 2021</Date>
                  <TitleItem>Prize Distribution</TitleItem>
                  <ProjectContainer whileHover={{ scale: 1.05 }}>
                    <ProjectImage src="https://via.placeholder.com/500" alt="Project" />
                    <Overlay className="overlay">
                      <IconLink href="https://github.com" target="_blank" rel="noopener noreferrer">
                        <FaGithub />
                      </IconLink>
                      <IconLink href="https://your-live-site.com" target="_blank" rel="noopener noreferrer">
                        <FaExternalLinkAlt />
                      </IconLink>
                    </Overlay>
                  </ProjectContainer>
                </div>
              </TimelineItem>

              <BottomImageContainer>
                <img src="/assets/images/celebration.jpg" alt="Celebration" />
              </BottomImageContainer>
            </TimelineContainer>
          </RightColumn>
        </Container>
      </div>
    </Section>
  );
};

export default JourneyTimeline;
