import React from 'react'
import GridVideoBackground from './GridVideoBackground'
import JourneyTimeline from './JourneyTimeline'
// import styled from "styled-components"
// import { motion } from 'framer-motion'

// const Container = styled(motion.div)`
//   width: 100%;
//   height: 100vh;
//   background-image: url('');
// `

const MyJourney = () => {
  return (
    <div >
      {/* <GridVideoBackground >
      </GridVideoBackground> */}
        <JourneyTimeline />
    </div>
  )
}

export default MyJourney