import React from "react"
import { NavLink } from "react-router-dom"
import styled from "styled-components"
// import { PowerBtn } from '../components/AllSvgs'
import HomeLogo from "../assets/Images/home.png"

const Power = styled.button`
  position: fixed;
  top: 2rem;
  left: 50%;
  transform: translate(-50%, 0);

  background-color: #00f0ff;
  padding: 0.3rem;
  border-radius: 50%;
  border: none;
  height: 2.5rem;
  width: 2.5rem;

  display: flex;
  justify-content: center;
  z-index: 3;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 240, 255, 0.6);
    box-shadow: 0 0 8px 6px rgba(0, 240, 255, 0.8);
  }

  & > *:first-child {
    text-decoration: none;
    color: inherit;
  }
`

const PowerButton = () => {
  return (
    <Power>
      <NavLink to="/">
        {/* <PowerBtn width={30} height={30} fill='currentColor' /> */}
        <img src={HomeLogo} width={30} height={30} alt="home" />
      </NavLink>
    </Power>
  )
}

export default PowerButton
