import React from 'react';
import styled from 'styled-components';
import { FaCheck } from 'react-icons/fa'; // Example for using FontAwesome icons

const Container = styled.div`
  padding: 1rem;
`;

const Timeline = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const TimelineItem = styled.div`
  display: flex;
  position: relative;
  padding-left: 2rem;

  &::before {
    content: "";
    background: #dadee4;
    height: 100%;
    left: 0.75rem;
    position: absolute;
    top: 1.25rem;
    width: 2px;
    z-index: -1;
  }
`;

const TimelineIcon = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.25rem;
`;

const Badge = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #000; /* Example border color */
`;

const TimelineContent = styled.div`
  flex: 1;
  padding-left: 1rem;
`;

const Card = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  max-height: 300px;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
`;

const CardBody = styled.div`
  padding: 1rem;
`;

const Label = styled.span`
  margin-left: auto;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  color: #fff;
  background-color: ${({ color }) => color};
`;

const LoadMore = styled.a`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  color: #000;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const TimelineComponent = () => (
  <Container>
    <Timeline>
      <TimelineItem>
        <TimelineIcon>
          <Badge><FaCheck /></Badge>
        </TimelineIcon>
        <TimelineContent>
          <Card>
            <CardHeader>
              <h3><time dateTime="2020-07-08">July 8</time></h3>
              <Label color="green">Feature</Label>
            </CardHeader>
            <CardBody>
              <p className="text-success">Fully responsive timeline you can add to your UIkit 3 project</p>
            </CardBody>
          </Card>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineIcon>
          <Badge><FaCheck /></Badge>
        </TimelineIcon>
        <TimelineContent>
          <Card>
            <CardHeader>
              <h3><time dateTime="2020-07-07">July 7</time></h3>
              <Label color="orange">Test</Label>
            </CardHeader>
            <CardBody>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
            </CardBody>
          </Card>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineIcon>
          <Badge><FaCheck /></Badge>
        </TimelineIcon>
        <TimelineContent>
          <Card>
            <CardHeader>
              <h3><time dateTime="2020-07-06">July 6</time></h3>
              <Label color="red">Fix</Label>
            </CardHeader>
            <CardBody>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
            </CardBody>
            <LoadMore href="#">
              <span>Load more</span>
            </LoadMore>
          </Card>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  </Container>
);

export default TimelineComponent;
