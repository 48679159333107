import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`

@font-face {
    font-family: "Cyberpunk";
    src: url("./fonts/Cyberpunk.ttf") format("truetype");
  }

*,*::before,*::after,h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
}
h1,h2,h3,h4,h5,h6{
    display: inline-block;
}
body{
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: "Cyberpunk", sans-serif;
}

@media only screen and (max-width: 600px) {
    .pic{
     position: absolute !important;
     top:33% !important;
    }
}

@media only screen and (max-width: 800px) {
    .pic{
     position: absolute !important;
     top:40% !important;
    }
}

@media only screen and (max-width: 800px){
.skill-box > div:nth-child(5){
    margin-top: 10rem !important;
}
.skill-box > div:nth-child(6){
    margin-top: 10rem !important;
}
}




    /* Scrollbar Styling */
::-webkit-scrollbar {
    width: 10px;
}
 
::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #000; 
    border:0.5px solid #fff;
}



`

export default GlobalStyle
