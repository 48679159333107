import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

const ScrollButton = styled(motion.button)`
  position: fixed;
  bottom: 20px;
  right: 0;
  left: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #00f0ff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 20px;
  display: ${({ show }) => (show ? "block" : "none")};
  transition: opacity 0.3s ease-in-out;
`

const ScrollToBottomButton = () => {
  const controls = {
    y: [0, -10, 0],
    transition: { duration: 0.8, repeat: Infinity },
  }

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    })
  }

  const handleScroll = () => {
    const button = document.getElementById("scrollButton")
    if (button) {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        button.style.display = "none"
      } else {
        button.style.display = "block"
      }
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <ScrollButton
      id="scrollButton"
      onClick={scrollToBottom}
      show={window.scrollY > 100}
      animate={controls}
      style={{ display: "block" }}
    >
      &darr;
    </ScrollButton>
  )
}

export default ScrollToBottomButton
