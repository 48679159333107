import React from "react"
import ReactDOM from "react-dom"
// import './index.css';
import App from "./App"
// import reportWebVitals from './reportWebVitals';
// import '../node_modules/normalize.css';
import { BrowserRouter } from "react-router-dom"
// import ParticlesAppTwo from './components/ParticleComponentGlaxy';
import ReactGA from "react-ga4"

ReactGA.initialize("G-3N1XV0V5J1")

ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname + window.location.search,
})

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
      {/* <ParticlesAppTwo /> */}
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
)
