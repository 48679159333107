import React from "react";
import "./LoadingProgress.css";
import bgHr from "../assets/Images/bg-hr.webp"
import bgVr from "../assets/Images/bg-vr.webp"

const LoadingProgress = ({ progress }) => {
    const isDesktop = window.innerWidth >= 1024
  return (
    <div className="container" style={{ backgroundImage: `url(${isDesktop ? bgHr : bgVr})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "100%", height: "100%", position: "fixed", top: "0", left: "0", zIndex: "9999"}}>
    <div className="range" style={{ "--p": progress }}>
      <div className="range__label">Loading</div>
    </div>
    </div>
  );
};

export default LoadingProgress;
