import React from 'react'
import styled from 'styled-components'
import PowerButton from '../subComponents/PowerButton';
import BgImage from '../assets/Images/stones2.png'
import { BsArrowRight } from 'react-icons/bs';
import { NavLink } from 'react-router-dom'


const Box = styled.div`
background-color: ${props => props.theme.body};
width: 100vw;
height:100vh;
position: relative;
overflow: hidden;
`

const InnerBox = styled.div`
background-image: url(${BgImage});
background-repeat:no-repeat;
height:100vh;
width:100vw;
opacity:0.8;
background-position:right center;

display:flex;
justify-content:center;
align-items: center;
`

const TextBox = styled.div`
text-align:center;
opacity:1 !important;
`

const Heading = styled.h2`
font-size:20rem;
color:black;
`

const Text = styled.h5`
font-size:1.5rem;
color:black;
`
const ButtonBox = styled.div``

const Button = styled.button`
width:280px;
height:60px;
border-radius:30px;
background-color:#000;
color:#fff;
margin-top:10px;
cursor:pointer;
border:none;

&:hover{
  border:1px solid #fff;
}
`
const ArrowRight = styled(BsArrowRight)`
position:relative;
top:5px;
left:5px;

`

const Contact = () => {
  return (
    <Box className=''>
        <PowerButton />
      
        <InnerBox>
        <TextBox>
            <Heading>
                404
            </Heading>
            <br/>
            <Text>
              I'll add this page soon..
            </Text>
            <br/>
            <ButtonBox>
            <NavLink to="/">
          <Button>
              Back To Home
              <ArrowRight/>
          </Button>
          </NavLink>
        </ButtonBox>
        </TextBox>
       
        </InnerBox>
    </Box>
  )
}

export default Contact