// NotFound.js
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PowerButton from '../subComponents/PowerButton'
import { motion } from 'framer-motion'
import img from "../assets/Images/patrick-tomasso-Oaqk7qqNh_c-unsplash.jpg"
import Snake from 'react-simple-snake';
// import Snake from 'snake-game-react';

// Styled components for the 404 page
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
`;

const ErrorBox = styled.div`
  background-color: rgba(0, 0, 0, 0.6); /* Gray transparent background */
  padding: 20px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  // margin-top: 80px;
`;

const NotFoundTitle = styled.h1`
  font-size: 4rem;
  margin-bottom: 10px;
  margin-top: 80px;
  color: #FFF93C; /* White text color */
  font-family: "Righteous";
   @media only screen and (max-width: 800px) {
    font-size: 2rem;
  }
`;

const NotFoundText = styled.p`
  font-size: 1.5rem;
  color: #61dafb; /* White text color */
  font-family: "Righteous";
  margin-bottom: 10px;
`;

const MainContainer = styled(motion.div)`
// background-image: url(${img});
background-image: url('https://res.cloudinary.com/dcdchgx6z/image/upload/v1723198685/retro-digital-art-illustration-person-using-radio-technology_ni2cng.jpg');
background-size: cover;
background-repeat: no-repeat;
background-attachment: fixed;
background-position: center;
`

const GameContainer = styled.div`
    postion: absolute;
    top: 50%;
    left: 50%;
    font-family: "Righteous";
    @media only screen and (max-width: 800px) {
      display: none;
    }
`

const GameButtonConatiner = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
`

const GameTitle = styled.h1`
  font-size: 2rem;
  color: #FFF93C;
  font-family: "Righteous";
   @media only screen and (max-width: 800px) {
    font-size: 2rem;
  }
`

const SnakeText = styled.span`
  font-size: 2rem;
  color: #61dafb;
  font-family: "Righteous";
   @media only screen and (max-width: 800px) {
    font-size: 2rem;
  }
`

const GameButton = styled.button`
  background-color: #61dafb;
  font-family: 'Press Start 2p';
  font-size: 30pt;
  outline: none;
   text-align: center;
	display: inline-block;
  outline: none;
  border: none;
	margin:5px;
    font-weight: bold;
    padding: 10px 0 10px 10px ;
    background-color: #FFF93C;
    text-shadow: -1px -1px black, 1px 1px white;
    color: #61dafb;
    -webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	-o-border-radius: 7px;
	border-radius: 7px;
    box-shadow: 0 .2em #61dafb; 
    cursor: pointer;

    &:active {
    box-shadow: none;
    position: relative;
    top: 0.2em;
  }
`

// Framer-motion config
const container = {

    hidden: { opacity: 0 },
    show: {
        opacity: 1,

        transition: {
            staggerChildren: 0.5,
            duration: 0.5,
        }
    }

}

const NotFound = () => {
  const [gameStart, setGameStart] = useState(false);

  const handleStartGame = () => {
    setGameStart(true);
  
  }

  useEffect(() => {
    if(gameStart) {

      document.getElementById('Score').style.color = "#61dafb";
      document.getElementById('GameBoard').style.background = "rgba(0, 0, 0, 0.5)";
    }
  }, [gameStart])

  return (
    <MainContainer
            variants={container}
            initial='hidden'
            animate='show'
            exit={{
                opacity: 0, transition: { duration: 0.5 }
            }}
        >
            <PowerButton />
            <Container>
      <ErrorBox>
        <NotFoundTitle>404 - Page Not Found</NotFoundTitle>
        <NotFoundText>The page you are looking for does not exist.</NotFoundText>
        {/* You can add additional content or links here */}
        <GameContainer>
     {gameStart ?
     
    //  <Snake
    //     color1="#61dafb"
    //     color2="#FFF93C"
    //     backgroundColor="#000"
    //     />
    <Snake percentageWidth={40} startSnakeSize={3} snakeColor="#61dafb" appleColor="#FFF93C" />
        : 
        <GameButtonConatiner>
         <GameTitle>Play <SnakeText>Snake</SnakeText>  Game</GameTitle>
        <GameButton onClick={handleStartGame}>Start</GameButton>
        </GameButtonConatiner>
        }  
    </GameContainer>
      </ErrorBox>
    </Container>
        </MainContainer>
    
  );
};

export default NotFound;
